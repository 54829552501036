import React, { Suspense, lazy } from "react"
import { graphql, Link } from "gatsby"
import styled from "@emotion/styled/macro"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { useCommonItemsQuery } from "../hooks/use-common-items-query"
import { Seo } from "../components/Seo"
import { Layout, Theme2, Device, Container } from "../components/Layout"
import { HeaderDesktop } from "../components/HeaderDesktop"
import { HeaderMobile } from "../components/HeaderMobile"
import { HeroInterior } from "../components/HeroInterior"
import {
  ReadMoreRightArrow,
  PaginationPrev,
  PaginationNext,
} from "../components/SVGs"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"
import { BackToTop } from "../components/BackToTop"

const InteriorPageCallout = lazy(() =>
  import("../components/InteriorPageCallout").then(module => ({
    default: module.InteriorPageCallout,
  }))
)
const Footer = lazy(() =>
  import("../components/Footer").then(module => ({
    default: module.Footer,
  }))
)

const BlogsContainer = styled.div`
  display: grid;
  margin: 84px 0 118px 0;
  a {
    display: block;
  }

  @media ${Device.lg} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem 2rem;
  }
`

const PostDate = styled.time`
  background-color: ${Theme2.colors.tertiary};
  color: ${Theme2.colors.light};
  text-transform: uppercase;
  padding: 9px 16px;
  display: inline-block;
  font-size: 15px;
`
const PostTitle = styled.p`
  margin-top: 1rem;
  font-size: 25px;
  line-height: 35px;
  font-family: ${Theme2.fonts.headings};
  margin-bottom: 0.75em;
  font-weight: 700;
  @media ${Device.md} {
    font-size: calc(25px + 9 * ((100vw - 768px) / 432));
    font-size: calc(35px + 9 * ((100vw - 768px) / 432));
  }
  @media ${Device.lg} {
    margin-top: 2rem;
  }
  @media ${Device.xl} {
    font-size: 34px;
    line-height: 44px;
  }
`
const PostExcerpt = styled.div``
const ReadMoreLink = styled(Link)`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: ${Theme2.colors.dark1};
  margin-bottom: 2rem;

  span {
    margin-right: 20px;
  }

  @media ${Device.lg} {
    margin-bottom: 0.5rem;
  }
`
const Pagination = styled.div`
  text-align: center;
  margin-bottom: 118px;

  svg {
    width: 30px;
  }
  @media ${Device.lg} {
    svg {
      width: auto;
    }
  }
`
const PaginationNumbersContainer = styled.div`
  display: inline-block;
  margin: 0 5px;

  .pageNum {
    color: ${Theme2.colors.dark2};
    border: 1px solid ${Theme2.colors.neutral};
    padding: 9px 11px;
    border-radius: 50%;
    text-decoration: none;
    margin: 0 5px;
    min-width: 40px;
    display: inline-block;
    box-sizing: border-box;
  }
  .pageNum:hover {
    background-color: ${Theme2.colors.light2};
    border: 1px solid ${Theme2.colors.light2};
    color: ${Theme2.colors.light};
  }
  .active.pageNum {
    color: ${Theme2.colors.light};
    background-color: ${Theme2.colors.tertiary};
    border: 1px solid ${Theme2.colors.tertiary};
    box-shadow: 0px 4px 7px 2px ${Theme2.colors.tertiary};
  }
  .paginationMobile {
    @media ${Device.lg} {
      display: none;
    }
  }
  .paginationDesktop {
    display: none;

    @media ${Device.lg} {
      display: block;
    }
  }
`
const FeaturedImageContainer = styled.div`
  position: relative;

  .inFeaturedImage {
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    @media ${Device.lg} {
      left: 36px;
      transform: none;
    }
  }
`
const InteriorPageCalloutContainer = styled.div`
  margin-bottom: 118px;
`
const BlogIndex = ({ data, pageContext: { totalPages, currentPage } }) => {
  const posts = data.allWpPost.nodes
  const data2 = useCommonItemsQuery()
  const image =
    data2.commonItemsBlogHeroImage.localFile.childImageSharp.gatsbyImageData
      .images.sources[1].srcSet

  let currentPage2 = currentPage

  let endPagination =
    currentPage === totalPages
      ? totalPages
      : 3 * (Math.floor(currentPage / 4) + 1)
  endPagination = endPagination >= totalPages ? totalPages : endPagination
  let startPagination = endPagination - 2
  startPagination = startPagination <= 1 ? 1 : startPagination

  let endPagination2 =
    currentPage2 === totalPages
      ? totalPages
      : 6 * (Math.floor(currentPage / 7) + 1)
  endPagination2 = endPagination2 >= totalPages ? totalPages : endPagination2
  let startPagination2 = endPagination2 - 5
  startPagination2 = startPagination2 <= 1 ? 1 : startPagination2

  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  const pagination = range(startPagination, endPagination, 1)

  const range2 = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  const pagination2 = range2(startPagination2, endPagination2, 1)

  if (!posts.length) {
    return (
      <p>
        No blog posts found. Add posts to your WordPress site and they'll appear
        here!
      </p>
    )
  }
  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      <Seo page="blogIndexPage" />
      <Layout>
        <HeaderMobile />
        <HeaderDesktop />
        <HeroInterior
          isItNull={false}
          image={image}
          alt={data2.commonItemsBlogHeroImageAltText}
          title={data2.commonItemsBlogHeroTitle}
          subHeading={data2.commonItemsPageSubtitle}
        />
        <Container>
          <BlogsContainer>
            {posts.map(post => {
              const featuredimage =
                post.featuredImage !== null
                  ? getImage(post.featuredImage.node.localFile)
                  : ``

              return (
                <div>
                  {post.featuredImage !== null ? (
                    <FeaturedImageContainer>
                      <GatsbyImage
                        image={featuredimage}
                        alt={
                          post.blogpostsfeaturedimage
                            .blogPostsFeaturedImageAltText
                        }
                      />
                      <PostDate className="inFeaturedImage">
                        {post.date}
                      </PostDate>
                    </FeaturedImageContainer>
                  ) : (
                    <PostDate>{post.date}</PostDate>
                  )}

                  <PostTitle>{post.title}</PostTitle>
                  <PostExcerpt
                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                  />
                  <ReadMoreLink to={`/blog${post.link}`}>
                    <span>Read More</span>
                    <ReadMoreRightArrow />
                  </ReadMoreLink>
                </div>
              )
            })}
          </BlogsContainer>
          <InteriorPageCalloutContainer>
            <Suspense fallback="loading">
              <InteriorPageCallout />
            </Suspense>
          </InteriorPageCalloutContainer>
          <Pagination>
            {currentPage !== 1 ? (
              <Link
                to={currentPage === 2 ? `/blog/` : `/blog/` + (currentPage - 1)}
              >
                <PaginationPrev color={Theme2.colors.tertiary} />
              </Link>
            ) : (
              <PaginationPrev color={Theme2.colors.neutral} />
            )}
            <PaginationNumbersContainer>
              <div className="paginationMobile">
                {pagination.map((number, index) => (
                  <Link
                    key={index}
                    to={number === 1 ? `/blog/` : `/blog/` + number}
                    className={
                      number === currentPage ? "active pageNum" : "pageNum"
                    }
                  >
                    {number}
                  </Link>
                ))}
              </div>
              <div className="paginationDesktop">
                {pagination2.map((number, index) => (
                  <Link
                    key={index}
                    to={number === 1 ? `/blog/` : `/blog/` + number}
                    className={
                      number === currentPage ? "active pageNum" : "pageNum"
                    }
                  >
                    {number}
                  </Link>
                ))}
              </div>
            </PaginationNumbersContainer>
            {currentPage !== totalPages ? (
              <Link to={`/blog/` + (currentPage + 1)}>
                <PaginationNext color={Theme2.colors.tertiary} />
              </Link>
            ) : (
              <PaginationNext color={Theme2.colors.neutral} />
            )}
          </Pagination>
        </Container>
        <Suspense fallback="loading">
          <Footer />
        </Suspense>

        <BackToTop />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        link
        date(formatString: "MMM DD, YYYY")
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
        blogpostsfeaturedimage {
          blogPostsFeaturedImageAltText
        }
      }
    }
    wp {
      allSettings {
        readingSettingsPostsPerPage
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default BlogIndex
