import React from 'react' 
import styled from '@emotion/styled/macro'
import { StaticImage } from "gatsby-plugin-image"
import { Theme2, Device } from "../Layout"


const Title = styled.div`
font-family: ${Theme2.fonts.headings};
font-size: 38px;
font-weight: 700;
@media ${Device.md} {
    font-size: calc(38px + 47 * ((100vw - 768px) / 432));
}
@media ${Device.xl}{
    font-size: 85px;
}
`
const SubHeading = styled.div`
font-style: italic;
font-size: 18px;
font-family: ${Theme2.fonts.alternateHeadings};
@media ${Device.md} {
    font-size: calc(18px + 7 * ((100vw - 768px) / 432));
}
@media ${Device.xl}{
    font-size: 25px;
}
`
const HeroOuterContainer = styled.div`
display: grid;
color: ${Theme2.colors.light};
background-color: rgba(${Theme2.colors.dark3}, .66);
text-align: center;


.featuredImageContainer{
    grid-area: 1/1;
    height: 203px;
    overflow: hidden;
    position: relative;
    display: grid;
    align-items: center;

    @media ${Device.sm}{
        height: calc(203px + 337 * ((100vw - 576px) / 624));
    }
    @media ${Device.xl}{
        height: 540px;
    }
}
img{
    min-height: 100%;
    position: absolute;
}
.gatsby-image-wrapper{
    min-height: 100%;
}
`
const HeroTextContainer = styled.div`
grid-area: 1/1;
position: relative;
background: linear-gradient(rgba(${Theme2.colors.dark3}, 0.66), rgba(${Theme2.colors.dark3}, 0.66));
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;
`

const HeroInterior = ({title, subHeading, image, alt, isItNull}) =>{
    
      
    return(
<HeroOuterContainer>
    <div className="featuredImageContainer">
    {isItNull !== null ?(
        <img srcSet={image} loading="eager" alt={alt}
        />
    ):(
<StaticImage src="../../images/default-featured-image.jpg" alt={alt} formats={["auto", "webp", "avif"]} loading="eager" quality={100}  />
    )
    }
</div>
        <HeroTextContainer>
<Title>{title}</Title>
        <SubHeading>{subHeading}</SubHeading>
        </HeroTextContainer>
          </HeroOuterContainer>
    )
}
export default HeroInterior