import React, { useState, useEffect } from 'react' 
import { css, keyframes } from '@emotion/react'
import { zoomIn } from 'react-animations'
import { Theme2, Device } from './Layout'

const BackToTop = () =>{
  if (typeof window !== 'undefined') {
    console.log(`Location: ${window.location.href}`);
  }
    const zoomInAnimation = keyframes`${zoomIn}`
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

      useEffect(() => {
        // Button is displayed after scrolling for 500 pixels
        const toggleVisibility = () => {
          if (window.pageYOffset > 10) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        };
    
        window.addEventListener("scroll", toggleVisibility);
    
        return () => window.removeEventListener("scroll", toggleVisibility);
      }, []);

    return(
        <>
        {isVisible && (
<button 
onClick={scrollToTop}
css={css`
appearance: none;
position: fixed;
    z-index: 3;
    bottom: 5%;
    right: 5%;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    line-height: 1;
    border: none;
    color: ${Theme2.colors.light};
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px 2px;
    background-color: ${Theme2.colors.tertiary};
    animation: 0.5s ${zoomInAnimation};

    @media ${Device.lg}{
        display: none;
    }
`}>
<i css={css`
display: inline-block;
width: 1em;
    height: 1em;
    border-right: 0.2em solid ${Theme2.colors.light};
    border-top: 0.2em solid ${Theme2.colors.light};
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
`} />
    </button>
    
        )
}
</>
    )
   
}
export {BackToTop}